import React, {useState} from 'react';
import {Button} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPrint, faSave, faEnvelope} from '@fortawesome/free-solid-svg-icons';
import Spinner from "react-bootstrap/Spinner";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import NoEmailProvidedModal from "../../Modal/NoEmailProvidedModal";

type Props = {
    formIsModified: boolean;
    loading: boolean;
    submitForm: () => Promise<void>;
    showSave: boolean;
    submitType: string;
    isValid : boolean;
    setSubmitType?: (submitType: any) => void;
    systemAlert?: (alertText : string, alertTimeout : number) => Promise<void>;
    showPrint?: boolean;
    showEmail?: boolean;
    showCount?: boolean;
    saveText?: string;
    saveTextAction?: string;
    saveTextPast?: string;
    saveIcon?: IconProp;
    disableAutoSave?: boolean;
    printText? : string;
    emailText? : string;
    emailHasValue? : boolean;
};

const FormControls = ({
    formIsModified,
    loading,
    submitForm,
    showSave,
    submitType,
    setSubmitType,
    isValid,
    systemAlert,
    showPrint,
    showEmail,
    showCount,
    saveText,
    saveTextAction,
    saveTextPast,
    saveIcon,
    disableAutoSave,
    printText,
    emailText,
    emailHasValue
} : Props) => {
    const [showNoEmailProvidedModal, setShowNoEmailProvidedModal] = useState<boolean>(false);

    React.useEffect(() => {
        if (!disableAutoSave) {
            const timer = setTimeout(() => {
                if (formIsModified && isValid && !loading && submitType === 'save') {
                    submitForm();
                }
            }, 15000);
            return () => clearTimeout(timer);
        }
    }, [submitForm, formIsModified, disableAutoSave, isValid]);

    const saveTextDefault = saveText ? saveText : 'Save';
    const saveTextActionDefault = saveTextAction ? saveTextAction : 'Saving';
    const saveTextPastDefault = saveTextPast ? saveTextPast : 'Saved';

    return (
        <>
            {showPrint && (
                <Button
                    className={showSave
                        ? 'mr-3 d-flex pt-2 flex-row align-content-center'
                        : 'd-flex mr-3 pt-2 flex-row align-content-center'
                    }
                    variant="outline-secondary"
                    onClick={async () => {
                        if (setSubmitType) {
                            setSubmitType('print');
                        }
                        await submitForm();
                    }}
                    disabled={loading || !isValid}
                >{loading && submitType === 'print' ? (
                    <React.Fragment>
                        <Spinner animation="border" size="sm" className="mt-1"/>
                        &nbsp; Creating
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <FontAwesomeIcon icon={faPrint} size="sm" className="mt-1"/>
                        &nbsp; {printText ? printText : 'Create PDF'}
                    </React.Fragment>
                )}</Button>
            )}
            {showCount && (
                <Button
                    className={showSave
                        ? 'mr-3 d-flex pt-2 flex-row align-content-center'
                        : 'd-flex mr-3 pt-2 flex-row align-content-center'
                    }
                    variant="outline-secondary"
                    onClick={async () => {
                        if (setSubmitType) {
                            setSubmitType('count');
                        }
                        await submitForm();
                    }}
                    disabled={loading || !isValid}
                >{loading && submitType === 'count' ? (
                    <React.Fragment>
                        <Spinner animation="border" size="sm" className="mt-1"/>
                        &nbsp; Generating Count
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <FontAwesomeIcon icon={faPrint} size="sm" className="mt-1"/>
                        &nbsp; Generate Count
                    </React.Fragment>
                )}</Button>
            )}
            {showEmail && (
                <Button
                    className={showSave
                        ? 'mr-3 d-flex pt-2 flex-row align-content-center'
                        : 'd-flex pt-2 flex-row align-content-center'
                    }
                    variant="outline-secondary"
                    onClick={async () => {
                        if (!emailHasValue) {
                            setShowNoEmailProvidedModal(true);
                        } else {
                            if (setSubmitType) {
                                setSubmitType('email');
                            }
                            await submitForm();
                            if (systemAlert) {
                                await systemAlert(
                                    'An email has been sent to the address provided', 10000
                                );
                            }
                        }
                    }}
                    disabled={loading || !isValid}
                >{loading && submitType === 'email' ? (
                    <React.Fragment>
                        <Spinner animation="border" size="sm" className="mt-1"/>
                        &nbsp; Emailing
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <FontAwesomeIcon icon={faEnvelope} size="sm" className="mt-1"/>
                        &nbsp; {emailText ? emailText : 'Email Link'}
                    </React.Fragment>
                )}</Button>
            )}
            {showSave && (
                <Button
                    variant={formIsModified ? (!isValid ? 'danger' : 'secondary') : (!isValid ? 'outline-danger' : 'outline-secondary')}
                    type="submit"
                    className="d-flex pt-2 flex-row align-content-center"
                    disabled={loading || !isValid}
                >
                {loading && submitType === 'save' ? (
                    <React.Fragment>
                        <Spinner animation="border" size="sm" className="mt-1"/>
                        &nbsp; {saveTextActionDefault}
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {saveIcon ? (
                            <FontAwesomeIcon icon={saveIcon} size="sm" className="mt-1"/>
                        ) : (
                            <FontAwesomeIcon icon={faSave} size="sm" className="mt-1"/>
                        )}
                        &nbsp; {formIsModified ? saveTextDefault : saveTextPastDefault}
                    </React.Fragment>
                )}</Button>
            )}
            <NoEmailProvidedModal
                show={showNoEmailProvidedModal}
                handleShowNoEmailProvidedModal={setShowNoEmailProvidedModal}
            />
        </>
    );
};

export default FormControls;
