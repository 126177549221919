import React, {useCallback, useState} from 'react';
import {FieldArray, Form, Formik, FormikValues} from "formik";
import {NameToValueMap} from "../FormBuilder";
import {Button, Col, Row, Nav} from "react-bootstrap";
import StandardField from "../Fields/StandardField";
import * as yup from 'yup';
import FormControls from '../Fields/FormControls';
import {List} from '../../../pages/RecordLocator';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus, faTrashAlt, faArrowUp, faArrowDown} from '@fortawesome/free-solid-svg-icons';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import Spinner from "react-bootstrap/Spinner";
import ModifiedPrompt from "../ModifiedPrompt";
import {getOptionsForField, phoneValidation} from "../../../utils/formHelpers";
import ConfirmDeleteAdultModal from "../../Modal/ConfirmDeleteAdultModal";

export type SignificantAdult = {
    'Addresses::UUID' : string;
    'Addresses::AdultSortOrder' : number;
    'Addresses::AdultLastName' : string;
    'Addresses::AdultFirstName' : string;
    'Addresses::AdultRelation' : string;
    'Addresses::AdultLivesWith' : string;
    'Addresses::AdultLegalGuardian' : string;
    'Addresses::AdultCourtesyTitle' : string;
    'Addresses::AdultEmail' : string;
    'Addresses::AdultEmailNotes' : string;
    'Addresses::AdultLanguage' : string;
    'Addresses::AdultSecondLanguage' : string
    'Addresses::AdultPermStreet' : string;
    'Addresses::AdultPermCity' : string;
    'Addresses::AdultPermState' : string;
    'Addresses::AdultPermZip' : string;
    'Addresses::AdultMail' : string;
    'Addresses::AdultPhone1' : string;
    'Addresses::AdultPhone2' : string;
    'Addresses::AdultPhone3' : string;
    'Addresses::AdultNotes1' : string;
    'Addresses::AdultNotes2' : string;
    'Addresses::AdultNotes3' : string;
    'Addresses::AdultStreet' : string;
    'Addresses::AdultCity' : string;
    'Addresses::AdultState' : string;
    'Addresses::AdultZip' : string;
    'Addresses::AdultDirectionsToHome' : string;
    'Addresses::AdultPreviousAddresses' : string;
};

type SASubmitType = 'save' | 'add';

type Props = {
    canSave : boolean;
    canDelete : boolean;
    siteLists : Array<List>;
    significantAdults : Array<SignificantAdult>;
    handleSignificantAdultsSubmit : (values : FormikValues) => void;
    handleAddSignificantAdult : () => Promise<SignificantAdult | undefined>;
    handleRemoveSignificantAdult : (significantAdultUUID : string) => Promise<void>;
};

const SignificantAdultsForm = ({
    canSave,
    canDelete,
    siteLists,
    significantAdults,
    handleSignificantAdultsSubmit,
    handleAddSignificantAdult,
    handleRemoveSignificantAdult
} : Props) => {
    const [formIsModified, setFormIsModified] = useState<boolean>(false);
    const [submitType, setSubmitType] = useState<SASubmitType>('save');
    const [scrollBar, setScrollBar] = useState<HTMLElement | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [showConfirmDelSigAdultModal, setShowConfirmDelSigAdultModal] = useState<boolean>(false);
    const [showModalId, setShowModalId] = useState<number>(0);

    const handleSetFormIsModified = useCallback(async (formIsModified : boolean) => {
        await setFormIsModified(formIsModified);
    }, [setFormIsModified]);

    const handleCloseConfirmDelSigAdultModal = useCallback(() => {
        setShowConfirmDelSigAdultModal(false);
    }, []);

    const initialValues : NameToValueMap = {
        significantAdults : significantAdults.map((significantAdult) => {
            return {
                ...significantAdult
            }
        })
    };

    const schema = yup.object({
        significantAdults: yup.array()
            .of(
                yup.object().shape({
                    'Addresses::UUID' : yup.string(),
                    'Addresses::AdultSortOrder' : yup.number(),
                    'Addresses::AdultLastName' : yup.string(),
                    'Addresses::AdultFirstName' : yup.string(),
                    'Addresses::AdultRelation' : yup.string(),
                    'Addresses::AdultLivesWith' : yup.string(),
                    'Addresses::AdultLegalGuardian' : yup.string(),
                    'Addresses::AdultCourtesyTitle' : yup.string(),
                    'Addresses::AdultEmail' : yup.string(),
                    'Addresses::AdultEmailNotes' : yup.string(),
                    'Addresses::AdultLanguage' : yup.string(),
                    'Addresses::AdultSecondLanguage' : yup.string(),
                    'Addresses::AdultPermStreet' : yup.string(),
                    'Addresses::AdultPermCity' : yup.string(),
                    'Addresses::AdultPermState' : yup.string(),
                    'Addresses::AdultPermZip' : yup.string(),
                    'Addresses::AdultMail' : yup.string(),
                    'Addresses::AdultPhone1' : phoneValidation,
                    'Addresses::AdultPhone2' : phoneValidation,
                    'Addresses::AdultPhone3' : phoneValidation,
                    'Addresses::AdultNotes1' : yup.string(),
                    'Addresses::AdultNotes2' : yup.string(),
                    'Addresses::AdultNotes3' : yup.string(),
                    'Addresses::AdultStreet' : yup.string(),
                    'Addresses::AdultCity' : yup.string(),
                    'Addresses::AdultState' : yup.string(),
                    'Addresses::AdultZip' : yup.string(),
                    'Addresses::AdultDirectionsToHome' : yup.string(),
                    'Addresses::AdultPreviousAddresses' : yup.string()
                })
            )
    });

    return (
        <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={async (values, formikHelpers) => {
            setSubmitType('save');
            setLoading(true);
            await handleSignificantAdultsSubmit(values);
            await handleSetFormIsModified(false);
            setLoading(false);
        }}
        enableReinitialize={true}
        >
        {(props) => {
            const formValues = props.values;

            const sortedAdults = formValues.significantAdults.sort((a: SignificantAdult, b: SignificantAdult) =>
                    a['Addresses::AdultSortOrder'] - b['Addresses::AdultSortOrder']) as SignificantAdult[];
            return <FieldArray
                    name="significantAdults"
                    render={(arrayHelpers: { push: (adult: SignificantAdult | undefined) => void; remove: (row: number) => void; }) => (
                        <Form className="chris-form-bg pt-2">
                        {canSave && (
                        <Row className="mx-0 px-2">
                            <Col xs={12} lg={4} className="d-flex flex-row justify-content-start">
                                <Button
                                    variant="secondary"
                                    type="button"
                                    className="d-flex pt-2 flex-row align-content-center"
                                    disabled={loading}
                                    onClick={async () => {
                                        setSubmitType('add');
                                        setLoading(true);
                                        const significantAdult = await handleAddSignificantAdult();
                                        arrayHelpers.push(significantAdult);
                                        const element = document.getElementById(
                                            `significant-adult-${significantAdult ? significantAdult['Addresses::UUID'] : ''}`
                                        );
                                        if (scrollBar) {
                                            scrollBar.scrollTop = element?.offsetTop ? element?.offsetTop : 0;
                                        }
                                        setLoading(false);
                                    }}
                                >{loading ? (
                                    <React.Fragment>
                                        <Spinner animation="border" size="sm" className="mt-1"/>
                                        &nbsp; Adding
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <FontAwesomeIcon icon={faPlus} size="sm" className="mt-1"/>
                                        &nbsp; Add Entry
                                    </React.Fragment>
                                )}</Button>
                            </Col>
                            <Col
                                xs={12}
                                lg={8}
                                className="d-flex flex-row justify-content-start justify-content-lg-end"
                            >
                                <FormControls
                                    formIsModified={formIsModified}
                                    loading={loading}
                                    submitForm={props.submitForm}
                                    showSave={canSave}
                                    submitType={submitType}
                                    disableAutoSave={false}
                                    isValid={props.isValid}
                                />
                            </Col>
                            <Col xs={12}>
                                <hr />
                            </Col>
                        </Row>
                        )}
                        <Row className="mx-0 p-0">
                            <Col xs={12} className="d-flex flex-row justify-content-center">
                                <Nav className="justify-content-center">
                                {sortedAdults.map((significantAdult, index ) => {
                                    return <Nav.Item key={`nav-${significantAdult['Addresses::UUID']}`}>
                                        <Nav.Link onClick={() => {
                                            const element = document.getElementById(`significant-adult-${significantAdult['Addresses::UUID']}`);
                                            if (scrollBar) {
                                                scrollBar.scrollTop = element?.offsetTop ? element?.offsetTop : 0;
                                            }
                                        }}>Adult {index+1}</Nav.Link>
                                    </Nav.Item>;
                                })}
                                </Nav>
                            </Col>
                        </Row>
                        <PerfectScrollbar containerRef={(ref) => { setScrollBar(ref); }}>
                            <div className="significant-adult-container pt-0 mt-0">
                                {sortedAdults.map((significantAdult, sortedIndex) => {
                                    const index = formValues.significantAdults.findIndex((a : SignificantAdult) => a['Addresses::UUID'] === significantAdult['Addresses::UUID']);
                                    return (
                                        <div
                                            key={significantAdult['Addresses::UUID']} className={`mx-4 mb-4 px-3 significant-adult`}
                                            id={`significant-adult-${significantAdult['Addresses::UUID']}`}>
                                            <Row className="mx-0">
                                                <Col xs={12} lg={6} className="pt-3 px-0">
                                                    <div
                                                        className="significant-adult-delete"
                                                    >Significant Adult {index+1}</div>
                                                </Col>
                                                {(canDelete || canSave) && (
                                                <Col
                                                    xs={12}
                                                    lg={6}
                                                    className="d-flex flex-row justify-content-start justify-content-lg-end pt-3 px-0"
                                                >
                                                    {(sortedIndex > 0 && <FontAwesomeIcon
                                                        onClick={async () => {
                                                            const oldSort = props.values.significantAdults[index]['Addresses::AdultSortOrder'];
                                                            const newSort = props.values.significantAdults[index-1]['Addresses::AdultSortOrder'];
                                                            await Promise.all([
                                                                props.setFieldValue(`significantAdults[${index}].Addresses::AdultSortOrder`, newSort),
                                                                props.setFieldValue(`significantAdults[${index-1}].Addresses::AdultSortOrder`, oldSort),
                                                                handleSetFormIsModified(true),
                                                            ]);
                                                        }}
                                                        icon={faArrowUp}
                                                        className={`mt-1 cursor-pointer ${sortedIndex < sortedAdults.length -1 ? 'mr-1' : 'mr-4'}`}
                                                        size="1x"
                                                    />)}
                                                    {(sortedIndex < sortedAdults.length -1 && <FontAwesomeIcon
                                                        onClick={async () => {
                                                            const oldSort = props.values.significantAdults[index]['Addresses::AdultSortOrder'];
                                                            const newSort = props.values.significantAdults[index+1]['Addresses::AdultSortOrder'];
                                                            await Promise.all([
                                                                props.setFieldValue(`significantAdults[${index}].Addresses::AdultSortOrder`, newSort),
                                                                props.setFieldValue(`significantAdults[${index+1}].Addresses::AdultSortOrder`, oldSort),
                                                                handleSetFormIsModified(true),
                                                            ]);
                                                        }}
                                                        icon={faArrowDown}
                                                        className="mt-1 cursor-pointer mr-4"
                                                        size="1x"
                                                    />)}
                                                    {canDelete && (<>
                                                        <div
                                                            className="mr-2 text-danger significant-adult-delete cursor-pointer"
                                                            onClick={() => {
                                                                setShowModalId(index);
                                                                setShowConfirmDelSigAdultModal(true);
                                                            }}
                                                        >Delete</div>
                                                        <FontAwesomeIcon
                                                            icon={faTrashAlt}
                                                            size="1x"
                                                            className="text-danger mt-1 cursor-pointer"
                                                            onClick={() => {
                                                                setShowModalId(index);
                                                                setShowConfirmDelSigAdultModal(true)
                                                            }}
                                                        />
                                                    </>)}
                                                    <ConfirmDeleteAdultModal
                                                        show={showConfirmDelSigAdultModal && showModalId === index}
                                                        handleClose={handleCloseConfirmDelSigAdultModal}
                                                        handleYes={async () => {
                                                            arrayHelpers.remove(index);
                                                            setShowConfirmDelSigAdultModal(false);
                                                            await handleRemoveSignificantAdult(
                                                                significantAdult['Addresses::UUID']
                                                            );
                                                        }}
                                                        message={`Are you sure you want to delete Significant Adult ${index+1}?`}
                                                    />
                                                </Col>
                                                )}
                                            </Row>
                                            <Row className="mx-0">
                                                <Col xs={12} className="mx-0 px-0">
                                                    <hr />
                                                </Col>
                                            </Row>
                                            <Row className="px-2">
                                                <Col xs={12} lg={4}>
                                                    <StandardField
                                                        name="Addresses::AdultLastName"
                                                        type="text"
                                                        label="Last Name"
                                                        disabled={!canSave}
                                                        handleSetFormIsModified={handleSetFormIsModified}
                                                        arrayName='significantAdults'
                                                        iteration={index}
                                                    />
                                                </Col>
                                                <Col xs={12} lg={4}>
                                                    <StandardField
                                                        name="Addresses::AdultFirstName"
                                                        type="text"
                                                        label="First Name"
                                                        disabled={!canSave}
                                                        handleSetFormIsModified={handleSetFormIsModified}
                                                        arrayName='significantAdults'
                                                        iteration={index}
                                                    />
                                                </Col>
                                                <Col xs={12} lg={4}>
                                                    <StandardField
                                                        name="Addresses::AdultRelation"
                                                        type="select"
                                                        label="Relation"
                                                        options={getOptionsForField(
                                                            'RelationSigOther',
                                                            '',
                                                            siteLists
                                                        )}
                                                        disabled={!canSave}
                                                        handleSetFormIsModified={handleSetFormIsModified}
                                                        arrayName='significantAdults'
                                                        iteration={index}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="px-2">
                                                <Col xs={12} lg={4}>
                                                    <StandardField
                                                        name="Addresses::AdultLivesWith"
                                                        type="radio"
                                                        label="Lives With"
                                                        options={getOptionsForField(
                                                            'YN',
                                                            '',
                                                            siteLists
                                                        )}
                                                        disabled={!canSave}
                                                        handleSetFormIsModified={handleSetFormIsModified}
                                                        arrayName='significantAdults'
                                                        iteration={index}
                                                    />
                                                </Col>
                                                <Col xs={12} lg={4}>
                                                    <StandardField
                                                        name="Addresses::AdultLegalGuardian"
                                                        type="radio"
                                                        label="Legal Guardian"
                                                        options={getOptionsForField(
                                                            'YN',
                                                            '',
                                                            siteLists
                                                        )}
                                                        disabled={!canSave}
                                                        handleSetFormIsModified={handleSetFormIsModified}
                                                        arrayName='significantAdults'
                                                        iteration={index}
                                                    />
                                                </Col>
                                                <Col xs={12} lg={4}>
                                                    <StandardField
                                                        name="Addresses::AdultCourtesyTitle"
                                                        type="select"
                                                        label="Courtesy Title"
                                                        options={getOptionsForField(
                                                            'Salutation', '', siteLists
                                                        )}
                                                        disabled={!canSave}
                                                        handleSetFormIsModified={handleSetFormIsModified}
                                                        arrayName='significantAdults'
                                                        iteration={index}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="px-2">
                                                <Col xs={12} lg={4}>
                                                    <StandardField
                                                        name="Addresses::AdultEmail"
                                                        type="text"
                                                        label="Email"
                                                        disabled={!canSave}
                                                        handleSetFormIsModified={handleSetFormIsModified}
                                                        arrayName='significantAdults'
                                                        iteration={index}
                                                    />
                                                </Col>
                                                <Col xs={12} lg={8}>
                                                    <StandardField
                                                        name="Addresses::AdultEmailNotes"
                                                        type="text"
                                                        label="Email Notes"
                                                        disabled={!canSave}
                                                        handleSetFormIsModified={handleSetFormIsModified}
                                                        arrayName='significantAdults'
                                                        iteration={index}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="px-2">
                                                <Col xs={12} lg={4}>
                                                    <StandardField
                                                        name="Addresses::AdultLanguage"
                                                        type="select"
                                                        label="Language One"
                                                        options={getOptionsForField(
                                                            'LanguageList',
                                                            '',
                                                            siteLists
                                                        )}
                                                        disabled={!canSave}
                                                        handleSetFormIsModified={handleSetFormIsModified}
                                                        arrayName='significantAdults'
                                                        iteration={index}
                                                    />
                                                </Col>
                                                <Col xs={12} lg={4}>
                                                    <StandardField
                                                        name="Addresses::AdultSecondLanguage"
                                                        type="select"
                                                        label="Language Two"
                                                        options={getOptionsForField(
                                                            'LanguageList',
                                                            '',
                                                            siteLists
                                                        )}
                                                        disabled={!canSave}
                                                        handleSetFormIsModified={handleSetFormIsModified}
                                                        arrayName='significantAdults'
                                                        iteration={index}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="px-2">
                                                <Col xs={12} className="px-2 mb-2 mt-2">
                                                    <h3>Physical Address</h3>
                                                    <hr />
                                                </Col>
                                                <Col xs={12}>
                                                    <StandardField
                                                        name="Addresses::AdultPermStreet"
                                                        type="text"
                                                        label="Address"
                                                        disabled={!canSave}
                                                        handleSetFormIsModified={handleSetFormIsModified}
                                                        arrayName='significantAdults'
                                                        iteration={index}
                                                    />
                                                </Col>
                                                <Col xs={12} lg={4}>
                                                    <StandardField
                                                        name="Addresses::AdultPermCity"
                                                        type="select"
                                                        label="City"
                                                        options={getOptionsForField(
                                                            'CitiesList',
                                                            '',
                                                            siteLists
                                                        )}
                                                        disabled={!canSave}
                                                        handleSetFormIsModified={handleSetFormIsModified}
                                                        arrayName='significantAdults'
                                                        iteration={index}
                                                    />
                                                </Col>
                                                <Col xs={12} lg={4}>
                                                    <StandardField
                                                        name="Addresses::AdultPermState"
                                                        type="text"
                                                        label="State"
                                                        disabled={!canSave}
                                                        handleSetFormIsModified={handleSetFormIsModified}
                                                        arrayName='significantAdults'
                                                        iteration={index}
                                                    />
                                                </Col>
                                                <Col xs={12} lg={4}>
                                                    <StandardField
                                                        name="Addresses::AdultPermZip"
                                                        type="text"
                                                        label="Zip"
                                                        disabled={!canSave}
                                                        handleSetFormIsModified={handleSetFormIsModified}
                                                        arrayName='significantAdults'
                                                        iteration={index}
                                                    />
                                                </Col>
                                                <Col xs={12} lg={4}>
                                                    <StandardField
                                                        name="Addresses::AdultMail"
                                                        type="radio"
                                                        label="Mail?"
                                                        options={getOptionsForField(
                                                            'YN',
                                                            '',
                                                            siteLists
                                                        )}
                                                        disabled={!canSave}
                                                        handleSetFormIsModified={handleSetFormIsModified}
                                                        arrayName='significantAdults'
                                                        iteration={index}
                                                        onChange={(e) => {
                                                            if (e === 'Y') {
                                                                const permStreet = props.getFieldMeta(`significantAdults[${index}].Addresses::AdultPermStreet`).value;
                                                                const permCity = props.getFieldMeta(`significantAdults[${index}].Addresses::AdultPermCity`).value;
                                                                const permState = props.getFieldMeta(`significantAdults[${index}].Addresses::AdultPermState`).value;
                                                                const permZip = props.getFieldMeta(`significantAdults[${index}].Addresses::AdultPermZip`).value;

                                                                props.setFieldValue(`significantAdults[${index}].Addresses::AdultStreet`, permStreet);
                                                                props.setFieldValue(`significantAdults[${index}].Addresses::AdultCity`, permCity);
                                                                props.setFieldValue(`significantAdults[${index}].Addresses::AdultState`, permState);
                                                                props.setFieldValue(`significantAdults[${index}].Addresses::AdultZip`, permZip);
                                                            }
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="px-2">
                                                <Col xs={12} lg={4}>
                                                    <StandardField
                                                        name="Addresses::AdultPhone1"
                                                        type="text"
                                                        label="Phone 1"
                                                        disabled={!canSave}
                                                        handleSetFormIsModified={handleSetFormIsModified}
                                                        arrayName='significantAdults'
                                                        iteration={index}
                                                    />
                                                </Col>
                                                <Col xs={12} lg={4}>
                                                    <StandardField
                                                        name="Addresses::AdultPhone2"
                                                        type="text"
                                                        label="Phone 2"
                                                        disabled={!canSave}
                                                        handleSetFormIsModified={handleSetFormIsModified}
                                                        arrayName='significantAdults'
                                                        iteration={index}
                                                    />
                                                </Col>
                                                <Col xs={12} lg={4}>
                                                    <StandardField
                                                        name="Addresses::AdultPhone3"
                                                        type="text"
                                                        label="Phone 3"
                                                        disabled={!canSave}
                                                        handleSetFormIsModified={handleSetFormIsModified}
                                                        arrayName='significantAdults'
                                                        iteration={index}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="px-2">
                                                <Col xs={12} lg={4}>
                                                    <StandardField
                                                        name="Addresses::AdultNotes1"
                                                        type="text"
                                                        label="Notes 1"
                                                        disabled={!canSave}
                                                        handleSetFormIsModified={handleSetFormIsModified}
                                                        arrayName='significantAdults'
                                                        iteration={index}
                                                    />
                                                </Col>
                                                <Col xs={12} lg={4}>
                                                    <StandardField
                                                        name="Addresses::AdultNotes2"
                                                        type="text"
                                                        label="Notes 2"
                                                        disabled={!canSave}
                                                        handleSetFormIsModified={handleSetFormIsModified}
                                                        arrayName='significantAdults'
                                                        iteration={index}
                                                    />
                                                </Col>
                                                <Col xs={12} lg={4}>
                                                    <StandardField
                                                        name="Addresses::AdultNotes3"
                                                        type="text"
                                                        label="Notes 3"
                                                        disabled={!canSave}
                                                        handleSetFormIsModified={handleSetFormIsModified}
                                                        arrayName='significantAdults'
                                                        iteration={index}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="px-2">
                                                <Col xs={12} className="px-2 mb-2 mt-2">
                                                    <h3>Mailing Address</h3>
                                                    <hr />
                                                </Col>
                                                <Col xs={12}>
                                                    <StandardField
                                                        name="Addresses::AdultStreet"
                                                        type="text"
                                                        label="Address"
                                                        disabled={!canSave}
                                                        handleSetFormIsModified={handleSetFormIsModified}
                                                        arrayName='significantAdults'
                                                        iteration={index}
                                                    />
                                                </Col>
                                                <Col xs={12} lg={4}>
                                                    <StandardField
                                                        name="Addresses::AdultCity"
                                                        type="select"
                                                        label="City"
                                                        options={getOptionsForField(
                                                            'CitiesList',
                                                            '',
                                                            siteLists
                                                        )}
                                                        disabled={!canSave}
                                                        handleSetFormIsModified={handleSetFormIsModified}
                                                        arrayName='significantAdults'
                                                        iteration={index}
                                                    />
                                                </Col>
                                                <Col xs={12} lg={4}>
                                                    <StandardField
                                                        name="Addresses::AdultState"
                                                        type="text"
                                                        label="State"
                                                        disabled={!canSave}
                                                        handleSetFormIsModified={handleSetFormIsModified}
                                                        arrayName='significantAdults'
                                                        iteration={index}
                                                    />
                                                </Col>
                                                <Col xs={12} lg={4}>
                                                    <StandardField
                                                        name="Addresses::AdultZip"
                                                        type="text"
                                                        label="Zip"
                                                        disabled={!canSave}
                                                        handleSetFormIsModified={handleSetFormIsModified}
                                                        arrayName='significantAdults'
                                                        iteration={index}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="px-2">
                                                <Col xs={12}>
                                                    <StandardField
                                                        name="Addresses::AdultDirectionsToHome"
                                                        type="textarea"
                                                        label="Directions To Home"
                                                        disabled={!canSave}
                                                        handleSetFormIsModified={handleSetFormIsModified}
                                                        arrayName='significantAdults'
                                                        iteration={index}
                                                        className="textarea-md"
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="px-2">
                                                <Col xs={12}>
                                                    <StandardField
                                                        name="Addresses::AdultPreviousAddresses"
                                                        type="textarea"
                                                        label="Previous Addresses"
                                                        disabled={!canSave}
                                                        handleSetFormIsModified={handleSetFormIsModified}
                                                        arrayName='significantAdults'
                                                        iteration={index}
                                                        className="textarea-md"
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                    );
                                })}
                            </div>
                        </PerfectScrollbar>
                        <ModifiedPrompt formIsModified={formIsModified} loading={loading}/>
                        </Form>
                    )}
                />
        }}
        </Formik>
    );
};

export default SignificantAdultsForm;
